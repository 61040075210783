<template>
  <div class="user-login">
    <div class="login-form">
      <h2>新型经营主体实名认证</h2>
      <el-form ref="form" :model="form" label-width="160px" :rules="formRules">
        <el-form-item label="营业执照" prop="businessLicense">
          <el-upload
            class="upload-demo"
            action=""
            :on-change="uploadYyzz"
            :on-remove="removeYyzz"
            :accept="'.jpg,.jpeg,.gif,.png,.pdf'"
            :limit="1"
            :auto-upload="false"
            name="营业执照"
            list-type="picture-card"
          >
            <el-button v-if="uploading == 0" size="small" type="primary"
              >点击上传</el-button
            >
            <el-button
              v-else-if="uploading == 1"
              size="small"
              type="primary"
              :loading="true"
              style="
                display: inline-flex;
                justify-content: center;
                align-items: center;
              "
              >上传中...</el-button
            >
            <el-button
              v-else-if="uploading == 2"
              size="small"
              type="primary"
              :loading="true"
              style="
                display: inline-flex;
                justify-content: center;
                align-items: center;
              "
              >努力识别中</el-button
            >
            <div slot="tip" class="el-upload__tip" style="margin-top: -6px">
              上传图片最大6M，支持：JPG/JPEG/GIF/PNG/PDF格式。
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="公司名称" prop="companyName">
          <el-input placeholder="" v-model="form.companyName"> </el-input>
        </el-form-item>

        <el-form-item label="统一社会信用代码" prop="socialCreditId">
          <el-input placeholder="" v-model="form.socialCreditId"> </el-input>
        </el-form-item>

        <el-form-item label="示范合作社级别" v-if="show">
          <el-select v-model="form.city" clearable>
            <el-option
              :value="'国家级示范合作社'"
              label="国家级示范合作社"
            ></el-option>
            <el-option
              :value="'省级示范合作社'"
              label="省级示范合作社"
            ></el-option>
            <el-option
              :value="'市级示范合作社'"
              label="市级示范合作社"
            ></el-option>
            <el-option
              :value="'县级示范合作社'"
              label="县级示范合作社"
            ></el-option>
            <el-option :value="'非示范合作社'" label="非示范合作社"></el-option>
          </el-select>
        </el-form-item>

        <!--组织所在地 精确到县 镇 村 3级 -->

        <el-form-item label="组织所在地" prop="areaCode">
          <el-cascader
            ref="address"
            :options="options"
            :props="props"
            style="width: 49%; margin-right: 2%"
            @change="cityChange"
          ></el-cascader>
          <el-cascader
            ref="address2"
            v-model="value2"
            :options="options2"
            :props="props"
            @change="roadChange"
            style="width: 49%"
          ></el-cascader>

          <div class="el-form-item__error" v-show="areaHasChild">
            请选到最后一级
          </div>
        </el-form-item>
        <el-form-item label="对公账号" prop="companyBankId">
          <el-input placeholder="" v-model="form.companyBankId"> </el-input>
        </el-form-item>
        <el-form-item label="所属银行">
          <el-input placeholder="" v-model="form.affiliatedBank"> </el-input>
        </el-form-item>
        <el-form-item
          label="法人身份证人像面"
          prop="bossCardFront"
          class="is-required"
        >
          <div class="upload">
            <div class="upload-box">
              <el-upload
                v-loading="loading"
                :element-loading-text="lo == 1 ? '努力识别中' : '上传中'"
                ref="front"
                action=""
                :auto-upload="false"
                :show-file-list="false"
                name="files"
                :on-change="
                  (file) => {
                    handleChange(file, '0');
                  }
                "
              >
                <img
                  slot="default"
                  :src="
                    imgUrl[0]
                      ? this.baseUrl + 'admin/file/get?ossFilePath=' + imgUrl[0]
                      : 'imgs/card-1.png'
                  "
                  alt=""
                />
              </el-upload>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="法人身份证国徽面"
          prop="bossCardBack"
          class="is-required"
        >
          <div class="upload">
            <div class="upload-box">
              <el-upload
                ref="back"
                action=""
                :auto-upload="false"
                :show-file-list="false"
                list-type="picture-card"
                :limit="1"
                name="files"
                :on-change="
                  (file) => {
                    handleChange(file, '1');
                  }
                "
              >
                <img
                  :src="
                    imgUrl[1]
                      ? this.baseUrl + 'admin/file/get?ossFilePath=' + imgUrl[1]
                      : 'imgs/card-2.png'
                  "
                  alt=""
                />
              </el-upload>
            </div>
            <p style="color: #ff6633; margin: 0">
              (特别提示：照片必须将身份证四个边框完整拍入)
            </p>
          </div>
        </el-form-item>
        <el-form-item label="法人/投资人姓名" prop="bossName">
          <el-input placeholder="" v-model="form.bossName"> </el-input>
        </el-form-item>
        <el-form-item label="法人/投资人身份证号" prop="bossCardId">
          <el-input placeholder="" v-model="form.bossCardId"> </el-input>
        </el-form-item>

        <el-form-item label="经营场所照片" prop="companyPhoto">
          <el-upload
            class="upload-demo"
            action=""
            :before-upload="beforeUpload2"
            :on-change="uploadImages"
            :on-remove="removeImages"
            :accept="'.jpg,.jpeg,.gif,.png'"
            multiple
            :limit="6"
            :auto-upload="false"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip" style="margin-top: -6px">
              单张图片最大6M，最多上传6张，支持:JPG/JPEG/GIF/PNG格式。
            </div>
          </el-upload>
        </el-form-item>

        <!-- 来控制联系人显示隐藏 -->
        <el-button
          class="btn"
          type="primary"
          style="margin-left: 10px; margin-bottom: 10px"
          @click="lxr = !lxr"
          >添加联系人</el-button
        >
        <div v-if="lxr">
          <el-form-item label="联系人" prop="contactName">
            <el-input
              placeholder=""
              v-model="form.contactName"
              style="width: 258px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系人身份证号">
            <el-input
              placeholder=""
              v-model="form.contactCard"
              style="width: 258px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系人电话" prop="contactPhone">
            <el-input
              style="width: 258px"
              v-model="form.contactPhone"
              placeholder="请输入联系人电话"
            ></el-input>
            <el-button
              class="btn"
              type="primary"
              @click="getSmsCode6"
              :disabled="flag6"
              style="padding: 10px; margin-left: 10px"
              >{{ flag6 ? `（${current6}） s` : "发送验证码" }}</el-button
            >
          </el-form-item>
          <el-form-item label="联系人短信验证码" prop="contactSmsCode">
            <el-input
              placeholder=""
              v-model="form.contactSmsCode"
              style="width: 258px"
            >
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <div>
        <el-button
          type="success"
          @click="onSubmitOther"
          style="padding: 12px 100px; margin-top: 20px"
          >提交</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { getXzqArr, getIdentityList } from "@/api/add";
import { removeEmptyChildren } from "@/js/utils/util";
import { authCompany, getSmsCodeNew, getyyzz, getsfz } from "@/api/certificate";
import { uploadFile, deleteFile } from "@/api/fileApi";
import { mapGetters } from "vuex";
import { bankCardAttribution } from "./index";
export default {
  data() {
    var checkArea = (rule, value, callback) => {
      // if (!value) {
      //   callback();
      //   return callback(new Error("所在地不为空"));
      // } else {
      setTimeout(() => {
        if (this.areaHasChild) {
          callback(new Error("请选到最后一级"));
        } else {
          callback();
        }
      }, 100);
      // }
    };
    var validateIdNumber = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入法人/投资人身份证号"));
      } else if (!/(^\d{15}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
        callback(new Error("身份证号码格式不正确"));
      } else {
        callback();
      }
    };
    return {
      show: false,
      lo: 0,
      loading: false,
      uploading: 0,
      yyzz: {}, // 营业执照数据
      value2: [], // 第二个级联选择器的值
      lxr: false, // 联系人显示隐藏
      max: 60,
      areaHasChild: false,
      options: [],
      options2: [],
      current5: 60,
      current6: 60,
      decreseInterval: null,
      decreseInterval2: null,
      yyzzIds: [],
      yyzzInfos: [],
      imagesIds: [],
      imagesInfos: [],
      flag5: false,
      flag6: false,
      regionOptions: [{}],
      props: {
        label: "areaName",
        children: "children",
        value: "id",
        checkStrictly: true,
        expandTrigger: "hover",
      },
      form: {
        bossCardBack: "",
        bossCardFront: "",
        areaCode: "",
        bossCardId: "",
        bossName: "",
        businessLicense: "",
        companyBankId: "",
        // companyBankPhone: "",
        // companyCode: "",
        companyName: "",
        companyPhoto: "",
        contactName: "",
        contactPhone: "",
        contactSmsCode: "",
        socialCreditId: "",
        userId: "",
        city: "",
        affiliatedBank: "",
        contactCard: "",
      },
      uploadUrl: "", // 产权凭证,
      fileList: [],
      formRules: {
        companyName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        // areaCode: [{ required: true, validator: checkArea, trigger: "change" }],
        areaCode: [{ required: true, message: " ", trigger: "change" }],
        // companyCode: [
        //   { required: true, message: "请输入组织机构代码", trigger: "blur" },
        // ],
        socialCreditId: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "blur",
          },
        ],
        companyBankId: [
          { required: false, message: "请输入对公账号", trigger: "blur" },
        ],
        // companyBankPhone: [
        //   { required: true, message: "请输入银行预留手机号", trigger: "blur" },
        // ],
        // bankSmsCode: [
        //   { required: true, message: "请输入银行手机验证码", trigger: "blur" },
        // ],
        bossName: [
          { required: true, message: "请输入法人/投资人姓名", trigger: "blur" },
        ],
        bossCardId: [
          {
            required: true,
            validator: validateIdNumber,
            trigger: "blur",
          },
        ],
        // bossCardFront: [
        //   {
        //     required: true,
        //     message: "请上传法人身份证人像面",
        //     trigger: "blur",
        //   },
        // ],
        // bossCardBack: [
        //   {
        //     required: true,
        //     message: "请上传法人身份证国徽面",
        //     trigger: "blur",
        //   },
        // ],
        contactName: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, message: "请输入联系人电话", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        // contactSmsCode: [
        //   {
        //     required: true,
        //     message: "请输入短信验证码",
        //     trigger: "blur",
        //   },
        // ],
        businessLicense: [
          { required: true, message: "请上传营业执照", trigger: "blur" },
        ],

        // companyPhoto: [
        //   { required: true, message: "请上传经营场所照片", trigger: "blur" },
        // ],
      },
      value: [],
      imgUrl: ["", ""], //身份证人像页的本地图片url
      imgInfo: [undefined, undefined], //身份证人像页的上传返回数据
      baseUrl: window.globalUrl.HOME_API,
    };
  },
  created() {
    this.getRegions();
  },
  mounted() {
    // 联系人默认
    // this.form.contactName = this.identityObj.contactName;
    this.form.contactPhone = this.identityObj.contactPhone ? this.identityObj.contactPhone:this.userInfo.phone;
  },
  watch: {
    "form.companyName": function () {
      // 要查找的合作社名字
      const cooperativesName = "合作社";

      // 使用 includes() 方法检查字段中是否包含合作社名字
      if (this.form.companyName.includes(cooperativesName)) {
        this.show = true;
        console.log("字段中包含合作社的名字");
      } else {
        this.show = false;
        console.log("字段中不包含合作社的名字");
      }
    },

    "form.companyBankId": function () {
      if (
        this.form.companyBankId.length >= 5 &&
        this.form.affiliatedBank == ""
      ) {
        const obj = bankCardAttribution(this.form.companyBankId + ""); // 调用查看所属银行
        if (obj != "error") {
          this.form.affiliatedBank = obj.bankName;
        } else {
          this.form.affiliatedBank = "";
        }
      }
    },
  },
  computed: { ...mapGetters(["userInfo", "identityObj"]) },
  methods: {
    // 联系人默认
    // lxrbut() {
    //   this.lxr = !this.lxr;
    //   // console.log(this.identityObj);
    //   // this.contactName = "15165";

    //   // console.log(" this.contactName", this.contactName);
    // },

    async cityChange(val) {
      console.log("val", val);
      if (val) {
        let params = { parentCode: val[val.length - 1] };
        let res = await getXzqArr(params);
        if (res.code == 0) {
          this.value2 = null;
          this.form.areaCode = val[val.length - 1];
          console.log("this.form.areaCode", this.form.areaCode);
          if (res.data.length > 0) {
            this.options2 = removeEmptyChildren(res.data, "children");
            // this.areaHasChild = true;
          } else {
            this.options2 = [];
            this.areaHasChild = false;
          }
        }
      }
    },
    roadChange(val) {
      let node = this.$refs.address2.getCheckedNodes()[0];
      console.log("node", node);
      if (node.children.length == 0) {
        this.areaHasChild = false;
        this.form.areaCode = val[val.length - 1];
      } else {
        this.areaHasChild = true;
        this.form.areaCode = val[val.length - 1];
      }
    },
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isPDF = file.type === "application/pdf";
      const isLt6M = file.size / 1024 / 1024 < 6;
      if (!isJPG && !isPDF) {
        this.$message.error("只能上传 JPG/JPEG/PNG/GIF/PDF 格式的图片或文件");
      }

      if (!isLt6M) {
        this.$message.error("上传图片或文件大小不能超过 6MB");
      }

      return isJPG || (isPDF && isLt6M);
    },
    beforeUpload2(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt6M = file.size / 1024 / 1024 < 6;
      if (!isJPG) {
        this.$message.error("只能上传 JPG/JPEG/PNG/GIF 格式的图片");
      }

      if (!isLt6M) {
        this.$message.error("上传图片或文件大小不能超过 6MB");
      }

      return isJPG || isLt6M;
    },

    //上传身份证
    async handleChange(file, type) {
      let imgIndex = type * 1; //在数组中的索引
      if (this.checkFormat(file.raw)) {
        if (type == 0) {
          this.loading = true;
        }

        //符合格式要求
        if (this.imgInfo[imgIndex]) {
          //更换新的照片时需要删除旧照片
          this.deleteFile(this.imgInfo[imgIndex]);
          this.imgInfo[imgIndex] = undefined;
        }
        let res = await this.uploadSfz(file.raw, type);
        // if(res.code==0){

        // }else{
        //   this.$message.error('上传失败！')
        // }
        console.log("res", res);
      }
    },
    checkFormat(file) {
      console.log("file", file);
      console.log("file.type", file.type);
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt6M = file.size / 1024 / 1024 < 6;
      if (!isJPG) {
        this.$message.error("只能上传 JPG/JPEG/PNG 格式的图片或文件");
      }
      if (!isLt6M) {
        this.$message.error("上传图片大小不能超过 6MB");
      }
      return isJPG && isLt6M;
    },
    async uploadSfz(file, type) {
      let imgIndex = type * 1; //在数组中的索引
      let param = new FormData();
      param.append("catalog", "sfz");
      param.append("file", file);
      // console.time('myCode')
      let res = await uploadFile(param);
      // console.timeEnd("myCode");
      console.log("res", res);

      // ocr认证身份证
      if (type == 0) {
        this.lo = 1;
        // console.time('myCode')
        let res1 = await getsfz({
          identity: res.data.thumbnailUrl
            ? res.data.thumbnailUrl
            : res.data.url,
        });
        // console.timeEnd("myCode");
        console.log("sfz", res1.data.result);
        // 如果是人头面的话执行
        if (res1.data.result[0].note == "back") {
          this.form.contactName = res1.data.result[0].detail.name;
          this.form.bossName = res1.data.result[0].detail.name;
          this.form.bossCardId = res1.data.result[0].detail.idn;
          // this.loading=false
          // this.lo=0
        }

        if (res.code == 0) {
          // this.$set(this.imgUrl, imgIndex, URL.createObjectURL(file));
          this.$set(this.imgUrl, imgIndex, res.data.url);
          this.imgInfo[imgIndex] = res.data.url;
          // this.loading = false;
          console.log("imgUrl", this.imgUrl);
        }
      } else {
        if (res.code == 0) {
          // this.$set(this.imgUrl, imgIndex, URL.createObjectURL(file));
          this.$set(this.imgUrl, imgIndex, res.data.url);
          this.imgInfo[imgIndex] = res.data.url;
          // this.loading = false;
          console.log("imgUrl", this.imgUrl);
        }else{
          this.$message.error('上传失败，请重新上传')
        }
      }

      this.loading = false;
    },
    async onSubmitOther() {
      this.form.businessLicense = this.yyzzInfos
        ? this.yyzzInfos.join(",")
        : "";
      this.form.userId =
        localStorage.getItem("loginStep1UserId") || this.userInfo.id;
      this.$refs.form.validate(async (valid) => {
        console.log("valid", valid);
        if (valid) {
          let params = {
            companyPhoto: this.imagesInfos ? this.imagesInfos.join(",") : "",
          };
          let cardId = {
            bossCardFront: this.imgUrl[0],
            bossCardBack: this.imgUrl[1],
          };
          let res = await authCompany({
            ...this.form,
            ...params,
            ...cardId,
          });
          if (res.code == 0) {
            this.$message.success("认证成功!即将跳转至登录页");
            setTimeout(() => {
              // this.loadIdentityList();
              this.$router.push({ name: "login" });
            }, 500);
          } else {
            this.$message.error(res.msg);
          }
        } else {
          this.$message.error("请完善发布信息");
        }
      });
    },
    async loadIdentityList() {
      let res = await getIdentityList();
      if (res.code == 0) {
        if (res.data.length == 1) {
          this.$store.dispatch("SetIdentityObj", res.data[0]);
          this.$router.push({ name: "homepage" });
        } else {
          this.$router.push({ name: "chooseRole" });
        }
      }
    },
    async getSmsCode5() {
      // 获取联系人手机验证码
      if (this.form.companyBankPhone) {
        let param = {
          phone: this.form.companyBankPhone,
          type: "5",
        };
        let res = await getSmsCodeNew(param);
        let { code, data, msg } = res;
        if (code == 0) {
          this.$message.success(msg);
          this.current5 = this.max;
          this.decreseInterval = setInterval(() => {
            if (this.current5 <= this.max && this.current5 > 0) {
              this.current5--;
              this.flag5 = true;
            } else if (this.current5 <= 0) {
              this.current5 = 0;
              this.flag5 = false;
              clearInterval(this.decreseInterval);
            }
          }, 1000);
        } else {
          this.$message.error(msg);
        }
      } else {
        this.$message.error("请先输入手机号！");
      }
    },
    async getSmsCode6() {
      // 获取联系人手机验证码
      if (this.form.contactPhone) {
        let param = {
          phone: this.form.contactPhone,
          type: "6",
        };
        let res = await getSmsCodeNew(param);
        let { code, data, msg } = res;
        if (code == 0) {
          this.$message.success(msg);
          this.current6 = this.max;
          this.decreseInterval2 = setInterval(() => {
            if (this.current6 <= this.max && this.current6 > 0) {
              this.current6--;
              this.flag6 = true;
            } else if (this.current6 <= 0) {
              this.current6 = 0;
              this.flag6 = false;
              clearInterval(this.decreseInterval2);
            }
          }, 1000);
        } else {
          this.$message.error(msg);
        }
      } else {
        this.$message.error("请先输入手机号！");
      }
    },
    async deleteFile(url) {
      let res = await deleteFile({ ossFilePath: url });
      if (res.code == 0 && res.data == true) {
        console.log("删除成功");
      }
    },
    removeYyzz(file) {
      let index = this.yyzzIds.indexOf(file.uid);
      // this.deleteFile(this.yyzzInfos[index]);
      this.yyzzIds.splice(index, 1);
      this.yyzzInfos.splice(index, 1);
      this.uploading = 0;
    },
    removeImages(file) {
      let index = this.imagesIds.indexOf(file.uid);
      // this.deleteFile(this.imagesInfos[index]);
      this.imagesIds.splice(index, 1);
      this.imagesInfos.splice(index, 1);
    },

    async uploadYyzz(file, filelist) {
      const isJPG =
        file.raw.type === "image/jpeg" || file.raw.type === "image/png";
      const isPDF = file.raw.type === "application/pdf";
      // const isLt6M = file.raw.size / 1024 / 1024 < 6;
      if (!isJPG && !isPDF) {
        this.$message.error("只能上传 JPG/JPEG/PNG/GIF/PDF 格式的图片或文件");
        const indexOfFile = filelist.indexOf(file);
        filelist.splice(indexOfFile, 1);
        return;
      }
      this.uploading = 1;
      // if (!isLt6M) {
      //   this.$message.error("上传图片或文件大小不能超过 6MB");
      //   const indexOfFile = filelist.indexOf(file);
      //   filelist.splice(indexOfFile, 1);
      //   return
      // }

      let res = await this.uploadFile(file, "yyzz");

      console.log("file", file, filelist);

      // this.yyzz
      // const base64 = await this.getBase64(file.raw);
      // console.log("64", base64);
      // const res1 = await this.sendRequest(base64);
      // console.log(res1);
    },

    async uploadImages(file, filelist) {
      let res = await this.uploadFile(file, "images");
    },
    async uploadFile(file, type) {
      let param = new FormData();
      param.append("catalog", "register");
      param.append("file", file.raw);
      // console.time('myCode')
      let res = await uploadFile(param);

      if (res.code == 0) {
        this.uploading = 2;
      } else {
        this.$message.error("上传失败");
        const indexOfFile = filelist.indexOf(file);
        filelist.splice(indexOfFile, 1);
      }

      // console.timeEnd('myCode'); // 结束计时并输出执行时间
      console.log("yyzz", res);
      console.time("myCode");
      let res1 = await getyyzz({
        identity: res.data.thumbnailUrl ? res.data.thumbnailUrl : res.data.url,
      });
      console.timeEnd("myCode"); // 结束计时并输出执行时间
      console.log("res-yyzz", res1);
      // 判断是否已经填过信息 填过则不赋值
      this.form.companyName = this.form.companyName
        ? this.form.companyName
        : res1.data.returnObj.detail.name;
      this.form.socialCreditId = this.form.socialCreditId
        ? this.form.socialCreditId
        : res1.data.returnObj.detail.union_id;
      if (res.code == 0) {
        if (type == "yyzz") {
          this.yyzzIds.push(file.uid);
          this.yyzzInfos.push(res.data.url);
          setTimeout(() => {
            this.form.businessLicense = this.yyzzInfos
              ? this.yyzzInfos.join(",")
              : "";
            this.$refs.form.validateField("businessLicense");
          }, 100);
        } else if (type == "images") {
          this.imagesIds.push(file.uid);
          this.imagesInfos.push(res.data.url);
        }
      }
      this.uploading = 0;
    },
    async getRegions() {
      // 获取行政区树
      let res = await getXzqArr({
        level: 3,
      });
      if (res.code == 0) {
        this.options = removeEmptyChildren(res.data, "children");
      }
    },
    // 将文件转换为 Base64
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          // console.log("1", reader.result);
          // 从结果中移除前缀"data:img/jpg;base64,"
          const base64 = reader.result.split(",")[1];

          resolve(base64);
        };
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>
<style lang="less" scoped>
h2 {
  margin-bottom: 30px;
}
.user-login {
  width: 1200px;
  margin: 0 auto;
  background: #f2f2f2;
  padding: 30px 0;
  .login-form {
    width: 520px;
    padding: 6px 140px 40px 110px;
    background-color: #ffffff;
    margin: 0px auto;
    text-align: center;
  }
}

.upload-box {
  /deep/.el-upload {
    width: 148px !important;
    height: 126px !important;
    img {
      width: 100%;
      height: 100%;
    }
  }
  // .el-upload-list--picture-card .el-upload-list__item {
  //   width: 301px;
  //   height: 261px;
  // }
}
/deep/.el-form-item__content {
  text-align: left;
}
/deep/.el-loading-spinner {
  // text-align: left;
  width: 39% !important;
}
/deep/.el-loading-text {
  // text-align: left;
  margin-top: -29px;
}
</style>
